import http from '../utils/request'

export interface paramData {
    tagId: number
    page: number
}
export interface commentInit {
    user_id: number
    answer_id: number
    comment: string
}
export interface collecttInit {
    userId: number | Function | string
    type: number
    choiceld?: string
    title?: string
    desc?: string
    difficulty?: number
}

export const topicAnswer = (page: number, tagId: number) => {
    return http.request({
        url: '/answer/tagdetail',
        method: 'get',
        params: {
            tagId: tagId,
            page: page
        }
    })
}

export const answer_comment = (id: number) => {
    return http.request({
        url: '/answer/answer_comment',
        method: 'get',
        params: { id }
    })
}

// 提交评论
export const submitComment = (data: commentInit) => {
    return http.request({
        url: '/answer/submit_comment',
        method: 'POST',
        data
    })
}

// 搜索
export const searchList = (title: string) => {
    return http.request({
        url: '/answer/search/list',
        method: 'GET',
        params: {
            title
        }
    })
}

// 收藏
export const submitCollect = (data: collecttInit) => {
    return http.request({
        url: '/auth/collect',
        method: 'POST',
        data
    })
}

// 删除收藏
export const deleteCollect = (data: {id: number, user_id: number}) => {
    return http.request({
        url: '/auth/collect/delete',
        method: 'POST',
        data
    })
}


// 查询收藏
export const getCollect = (userId: number) => {
    return http.request({
        url: '/auth/getcollect',
        method: 'get',
        params: {
            userId
        }
    })
}
