// 问答题列表
export interface topicList {
    desc: string
    difficulty: number
    id: number
    title: string
}

export interface tag {
    tag: string
}

export interface page {
    current_path: number
    row: number
    topic_num: number
}

export class topic_answer {
    answerlist: topicList[] = []
    currt_page: number = 0
    arrlist: [] = []
}

// 问答题详情
interface detailInit {
    desc: string,
    difficulty: number
    id: number
    title: string
}
interface topic_commentInit {
    cid: number,
    comment: string
    createAt: string,
    user: userInit
}
interface userInit {
    avatar: string
    username: string
    is_vip: boolean
}

export class topic_answer_detail {
    detailList: detailInit[] = []
    title: string = ''
    difficulty: number = 0
    mdTxt: string = ''
    answer_id: number = 0
    comment: topic_commentInit[] = []
}

// 搜索
interface searchInit {
    id: number,
    title: string
}
export class search_list {
    list: searchInit[] = []
}

// 收藏查询
interface getCollect {
    answer_id: number
    id: number
    title: string
    type: number
    userId: number
    createAt: string
}
interface errorListInit {
    choice_question_titlt: any,
    createAt: string
    is_right: boolean
    user_answer: string
    user_id: number
}
export class collectList {
    list: getCollect[] = []
    listChoise: getCollect[] = []
    listTopic: getCollect[] = []
    errorList: errorListInit[] = []
}